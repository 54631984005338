.manage-block {
    display: flex;
    color: #0095D6;
    font-size: 16px;
    font-weight: 700;
    margin: 16px 0;
    justify-content: flex-end;
}

.address-token-name {
    margin-left: 0px;
}