.description-text {
    margin: 10px 0;
    color: #59779A;
    font-size: 12px;
    font-weight: 400;
}

.btn {
    padding: 0 50px;
}
