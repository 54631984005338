.transactions-details-block span{
    font-weight: 400;
}

.transactions-details-block .transaction-address{
    color: #5639E0;
}

.transactions-details-block .transactions-amount{
    font-weight: 700;
    color: #5639E0;
}

.transactions-details-block .transaction-ticker{
    color: black;
    margin-left: 4px;
}

.transactions-details-fee{
    font-weight: 700 !important;
    color: #0091A6;
}

.transactions-status{
    color: #0F8932;
    font-weight: 700 !important;
} 

.transactions-status-failed {
    color:  #CC3030;
    font-weight: 700 !important;
}

.transactions-details-block .row .custom-icon{
    margin-left: 4px;
}

.transaction-datetime{
    font-weight: 600 !important;
    color: #6B93C0;
}

.no-transactions-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 17% auto auto;
}

.no-transactions-block h3{
    margin-bottom: 0;
    font-weight: 700;
}

.no-transactions-block p{
    color: #28436E;
    text-align: center;
    width: 70%;
    font-size: 15px;
}

.transactions-link {
    text-decoration: none;
    color: #0091A6;
}

.mobile-link {
    display: none;
}
.center .pagination {
    margin-top: 20px;
    margin-bottom: 12px;
}


@media screen and (max-width: 520px) {
    .mobile-link {
        display: block;
    }

    .web-link {
        display: none;
    }
}
