.token__card-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    cursor: default;
    box-shadow: none;
    padding: 0;
    padding-right: 16px;
}

.token__card {
    display: flex;
    align-items: center;
    width: 85%;
    padding: 16px;
    cursor: pointer;
}

.token__card-not-allowed, .token__card-not-allowed .token__card {
    box-shadow: none !important;
    cursor: not-allowed;
}

.token__card * {
    margin: 0;
}

.token__card p {
    margin-left: 10px;
    color: #292929;
    font-size: 18px;
    font-weight: 500;
}


.div--center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div--center > .checkbox {
    margin-bottom: 20px;
}

.modal__description {
    color: #6B93C0;
}

.token__link {
    width: 42px;
    height: 42px;
    background-color: #DFE6FF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.token__card .token__address, .token__desc {
    color:#6B93C0;
    font-size: 14px;
    font-weight: 400;
}

.token__desc {
    font-weight: 500;
}

.token__card .token__name {
    color: #292929;
    font-size: 16px;
    font-weight: 700;
}

.token__name > span {
    color: #59779A;
    font-weight: 600;
    font-size: 16px;
}

.token__card > img {
    width: 30px;
    height: 30px;
    border-radius: 6px;
}

.custom-token-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-token__count {
    color: #6B93C0;
    font-size: 16px;
    font-weight: 700;
}

.custom-token__error {
    color: #CC3030;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
}

.custom-token__error::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border-bottom: 1px dashed #CC3030;
}

.token__action {
    display: flex;
}

.token__action > a {
    margin-right: 10px;
}

.token__source {
    color: #6B93C0;
    font-size: 14px;
}

.no-data {
    font-size: 14px;
    font-weight: 700;
    color: #EA2929;
}
