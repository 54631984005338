:root {
  --appThemeColor: white; 
}
html, body {
  height: 100%;
  margin: 0px;
  box-sizing: border-box;
  overflow: hidden;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--appThemeColor); 
}

.swap-confirm-block__balance span {
  margin-left: 4px !important;
}